/**
 * @description axios配置
 * @author lijing
 * @Date 2021/12/21 - 3:48 PM
 * */
import axios from 'axios';

const config = {
    timeout: 5000,
    withCredentials: false,
    responseType: 'json',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    xsrfCookieName: 'XSRF-TOKEN',
    headers: { 'Content-Type': 'application/json' }
};

const Service = axios.create(config);

// 添加请求拦截器
Service.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
});

// 添加响应拦截器
Service.interceptors.response.use(response => {
    return response.data;
}, error => {
    return Promise.reject(error);
});

export default Service;
