import Layout from '@Core/layout/aside';
const boardRoute = [
  {
    path: '/answerBoard',
    name: 'AnswerBoard',
    component: Layout,
    redirect: '/answerBoard/list',
    children: [
      {
        path: 'list',
        name: 'AnswerBoardList',
        component: () => import('@/views/AnswerBoard'),
        meta: { title: '问题列表' }
      },
      {
        path: 'ask',
        name: 'Ask',
        component: () => import('@/views/AnswerBoard/ask'),
        meta: { title: '提问页面' }
      },
      {
        path: 'detail',
        name: 'Detail',
        component: () => import('@/views/AnswerBoard/detail'),
        meta: { title: '详情页面' }
      },
      {
        path: 'edit',
        name: 'Edit',
        component: () => import('@/views/AnswerBoard/edit'),
        meta: { title: '修改问题' }
      },
    ]
  }
];

export default boardRoute;
