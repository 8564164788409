<template>
  <div class="common-header">
    <div class="common-header__wrap">
      <div class="common-header__info">
        <a href="//www.chinaacc.com" target="_blank" title="正保会计网校" class="logo">
          <img src="//www.chinaacc.com/images/logo.png" width="154" height="42">
        </a>
        <p class="title">{{ schoolName }}</p>
      </div>
      <div class="common-header__menu">
        <div v-if="showToHome" class="toHome">
          <router-link to="/">
            <icons icon-class="home"/>
            我的主页
          </router-link>
        </div>
        <div class="app-download">
          <el-dropdown trigger="hover">
            <span class="name"><icons icon-class="mobile2"/> APP下载</span>
            <el-dropdown-menu slot="dropdown" class="header-app">
              <el-dropdown-item>
                <img src="@/assets/images/login_app.png" alt="正保云平台APP">
                <p class="tip">扫码下载<br>{{ $settings.title }}App</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="isShowHelpBtn" class="help-btn" @click="openHelp">
          <a href="javascript:void (0)">
            <icons icon-class="help"/>
            帮助</a>
        </div>
        <div v-if="useUser" class="user-select">
          <el-dropdown trigger="hover" placement="bottom-end" @command="handleCommand">
            <div class="userInfo">
              <el-avatar :size="30" :src="avatar">
                <img :src="$settings.avatar">
              </el-avatar>
              <div class="userName">
                <span class="name">{{ nickName }}</span>
                <i class="el-icon-arrow-down el-icon--right"/>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-setting" command="personal">设置</el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button" command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from '@Core/utils';

export default {
  name: 'CommonHeader',
  props: {
    isShowHelpBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      schoolName: '',
      nickName: '',
      avatar: '',
      showToHome: true,
      useUser: false
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        const whiteList = [ '/forgot/confirm', '/forgot/certification', '/forgot/reset' ];
        this.showToHome = val.name !== 'Home';
        if (whiteList.indexOf(val.path) === -1) {
          this.initData();
        }
      }
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'personal') {
        this.$router.push({ path: '/personal/index' });
      } else if (command === 'logout') {
        this.$store.dispatch('logout');
      }
    },
    async initData() {
      const userInfo = await this.$store.dispatch('getUserInfo');
      if (userInfo) {
        const { schoolName, username, iconurl } = userInfo;
        this.useUser = true;
        this.schoolName = schoolName;
        this.nickName = username;
        this.avatar = iconurl;
      }
    },

    openHelp() {
      const { href } = this.$router.resolve({
        path: '/help/index'
      });
      window.open(href, '_blank');
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/mixin";

.common-header {
  height: 70px;
  width: 100%;
  background-color: #fff;

  &__wrap {
    @include layout;
    display: flex;
    height: 70px;
  }

  &__info {
    width: 70%;
    @include clearfix;

    .logo {
      float: left;
      width: 168px;
      height: 44px;
      display: inline-block;
      margin-top: 13px;
    }

    .title {
      float: left;
      height: 38px;
      margin: 16px 0 16px 24px;
      line-height: 38px;
      font-size: 22px;
      color: #333;
      border-left: 1px solid #E0E0E0;
      padding-left: 20px;
    }
  }

  &__menu {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .user-select {
      height: 30px;
      margin-top: 20px;
      margin-left: 45px;

      .userInfo {
        display: flex;
        cursor: pointer;

        .userName {
          height: 30px;
          line-height: 30px;
          margin-left: 15px;
          font-size: 14px;
          color: #333;

          &:hover {
            .name {
              color: #3095FC
            }

            .el-icon--right {
              color: #3095FC
            }
          }
        }
      }
    }

    .app-download {
      height: 70px;

      .name {
        display: block;
        width: 100%;
        height: 30px;
        line-height: 30px;
        color: #333;
        font-size: 14px;
        margin-top: 20px;
        cursor: pointer;

        &:hover {
          color: #3095FC;
        }
      }
    }

    .help-btn {
      line-height: 70px;
      margin-left: 30px;

      a {
        color: #333;
        font-size: 14px;
        height: 30px;
        margin-top: 20px;
        display: block;
        line-height: 30px;
      }
    }

    .toHome {
      height: 70px;
      margin-right: 30px;

      a {
        color: #333;
        font-size: 14px;
        height: 30px;
        margin-top: 20px;
        display: block;
        line-height: 30px;

        &:hover {
          color: #3095FC;
        }
      }
    }
  }
}

.header-app {
  img {
    width: 120px;
    height: 120px;
  }

  .tip {
    width: 120px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    color: #666;
  }

  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: transparent;
  }
}
</style>
