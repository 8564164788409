/**
 * @description cookie管理
 * @author lijing
 * @Date 2021/12/3 - 6:11 PM
 * */
import jsCookie from 'js-cookie';
import settings from '../../../setting';


export default {
    get: (key) => {
        if (key) {
            return jsCookie.get(key);
        }
    },
    set: (key, value, expires = undefined) => {
        let opt = {
            path: '/',
            secure: false,
            raw: false,
            domain: settings.domain,
            expires: expires
        };

        jsCookie.set(key, value, opt);
    },
    remove: (key, expires = undefined) => {
        let opt = {
            path: '/',
            secure: false,
            raw: false,
            domain: settings.domain,
            expires: expires
        };
        jsCookie.remove(key, opt);
    }
};