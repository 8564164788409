import Layout from '@Core/layout/aside';
const lectureNotesRoute = [
  {
    path: '/lectureNotes',
    name: 'LectureNotes',
    component: Layout,
    redirect: '/lectureNotes/list/:courseID',
    children: [
      {
        path: 'list/:courseID/',
        name: 'LectureNotesList',
        component: () => import('@/views/LectureNotesList'),
        meta: { title: '讲义列表' }
      },
      {
        path: 'download/:courseID/:cwareID',
        name: 'LectureNotesDownload',
        component: () => import('@/views/LectureNotesList/downLoad'),
        meta: { title: 'word下载' }
      },
      {
        path: 'detail/:courseID/:cwareID/:smallListID',
        name: 'LectureNotesDetail',
        component: () => import('@/views/LectureNotesList/detail'),
        meta: { title: '讲义查看' }
      }
    ]
  }
];

export default lectureNotesRoute;
