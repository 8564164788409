/**
 * @description 公共接口
 * @author lijing
 * @Date 2021/12/21 - 3:48 PM
 * */
import axios from '@Core/plugins/service/axios';

class ComApi {
    // 请求
    sendData(data) {
        data = {
            platform: '',
            appType: '',
            appVersion: '',
            ...data
        };
        return axios({
            url: '//ops.cdeledu.com/op/',
            method: 'post',
            data
        });
    }

    // 获取服务器时间
    getServerTime() {
        return axios({
            url: '//ops.cdeledu.com/op/',
            method: 'post',
            data: {
                domain: 'cdel',
                resourcePath: '+/server/time',
                time: +new Date(),
                platform: '',
                appType: '',
                appVersion: ''
            }
        });
    }

    // 获取公共key
    getPublicKeyApi() {
        return axios({
            url: '//ops.cdeledu.com/op/',
            method: 'post',
            data: {
                time: +new Date(),
                domain: 'cdel',
                resourcePath: '+/key/public',
                platform: '',
                appType: '',
                appVersion: ''
            }
        });
    }

    // 图片上传
    uploadImg(data) {
        return axios({
            url: '/BUS/uploadFile.shtm',
            method: 'post',
            data
        });
    }
}

const comApi = new ComApi();
export { comApi };
