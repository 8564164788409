/**
 * @description 课程中心
 * @author lijing
 * @Date 2021/12/27 - 10:33 AM
 * */
import Layout from '@Core/layout/aside';

const courseRoute = [
    {
        path: '/course',
        name: 'Course',
        component: Layout,
        children: [
            {
                path: ':courseID/:subjectID',
                name: 'CourseCenter',
                component: () => import('@/views/CourseCenter'),
                meta: { title: '课程中心' }
            }
        ]
    }
];

export default courseRoute;