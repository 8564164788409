/**
 * @description 课程相关接口
 * @author lijing
 * @Date 2022/1/5 - 4:24 PM
 * */
import { $http } from '@Core/plugins/service';

class CourseApi {
    // 获取学习累计时长
    getUserStudyTimeByIDsApi(params) {
        return $http('/ops/study/getUserStudyTimeByIDs', params);
    }

    // 根据课程获取课件列表
    getUserCourseListApi(params) {
        return $http('/ops/api/cware/getCwareByCourseID', params);
    }

     //根据课件获取视频列表
    getCwareVideoListApi(params) {
        return $http('/ops/api/cware/getCwareVideoList', params);
    }

     //获取视频未播放区间
    queryTraceResultApi(params) {
        return $http('/ops/api/cware/queryTraceResult', params);
    }
    // 根据多个cwareID获取笔记数量
    getUserNotesLengthApi(params) {
        return $http('/api/cware/note/getUserNoteCount', params);
    }

    // 获取视频播放hls地址
    getHlsPathApi(params) {
        return $http('/api/cware/player/getOpsVideoPathForHlsByPC', params);
    }

    // 获取课件目录列表
    getCwareCatalogListApi(params) {
        return $http('/api/cware/cware/getCwareCatalogList', params);
    }

    // 根据课件集合获取最后看课信息
    getUserLastLearnTimeApi(params) {
        return $http('/ops/api/cware/getUserLastLearnTime', params);
    }

    // 获取课件学习时长信息
    getCwareStudyTimeInfoApi(params) {
        return $http('/api/cware/cware/getCwareStudyTimeInfo', params);
    }

    // 添加用户笔记
    addUserNoteApi(params) {
        return $http('/api/cware/note/addUserNote', params);
    }

    // 获取用户所有的笔记内容（视频维度）
    getUserNoteContentApi(params) {
        return $http('/api/cware/note/getUserNoteContent', params);
    }

    // 获取最后视频播放信息
    getLastVideoPlayInfoApi(params) {
        return $http('/ops/api/cware/getLastVideoPlayInfo', params);
    }

    // 课件计时
    saveCwareTimeApi(params) {
        return $http('/api/cware/cware/saveCwareKcjyTime', params);
    }

    // 判断当前课程是否有答疑
    getAnswerAuth(params) {
        return $http('/ops/cware/getAnswerAuth', params);
    }

    // 获取线路
    getNetWorkListApi() {
        return $http('/ops/player/lines');
    }

    // 获取直播回放列表数据
    selectLivePlaybackListApi(params) {
        return $http('/ops/api/cware/selectLivePlayback', params);
    }

    // 直播回看获取播放地址的接口
    getLivePlaybackPathApi(params) {
        return $http('/ops/api/cware/getLivePlaybackPath', params);
    }

    // 获取遮挡参数
    getShelter(params) {
        return $http('/ops/stat/cware/getVideoCoverParams', params);
    }

}

const courseApi = new CourseApi();
export { courseApi };
