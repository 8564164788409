/**
 * @description 学习记录
 * @author lihui
 * @Date 2023/2/28 - 11:28 AM
 * */
import Layout from '@Core/layout/aside';
const questionBankRoute = [
    {
        path: '/record',
        name: 'Record',
        component: Layout,
        redirect: '/record/index',
        children: [
            {
                path: 'index',
                name: 'record',
                component: () => import('@/views/Record/index'),
                meta: { title: '学习记录' }
            },
            {
                path: 'allAnalysis',
                name: 'allAnalysis',
                component: () => import('@/views/QuestionBank/allAnalysis'),
                meta: { title: '全部解析' }
            },
            {
                path: 'doExam',
                name: 'doExam',
                component: () => import('@/views/QuestionBank/doExam'),
                meta: { title: '题库系统' }
            }
        ]
    }
];

export default questionBankRoute;