/**
 * @description 工具类
 * @author lijing
 * @Date 2021/12/3 - 6:11 PM
 * */
import md5Encrypt from 'js-md5';
import keys from '@Core/plugins/service/keyconst';

export const isExternal = path => {
    return /^(https?:|mailto:|tel:)/.test(path);
};
/**
 * 是否数组
 */
export const isArray = value => {
    if (typeof Array.isArray === 'function') {
        return Array.isArray(value);
    } else {
        return Object.prototype.toString.call(value) === '[object Array]';
    }
};
/**
 * 是否对象
 */
export const isObject = value => {
    return Object.prototype.toString.call(value) === '[object Object]';
};
/**
 * 判断是否为空
 */
export const isEmpty = val => {
    switch (typeof val) {
        case 'undefined':
            return true;
        case 'string':
            if (val.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true;
            break;
        case 'boolean':
            if (!val) return true;
            break;
        case 'number':
            if (val === 0 || isNaN(val)) return true;
            break;
        case 'object':
            if (val === null || val.length === 0) return true;
            for (const i in val) {
                return false;
            }
            return true;
    }
    return false;
};
/**
 * 是否json字符串
 */
export const isJsonString = value => {
    if (typeof value === 'string') {
        try {
            const obj = JSON.parse(value);
            if (typeof obj === 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
};
/**
 * 时间格式转换
 * */
export const FormatDateTime = (inputTime = '', shortDate = true, character = ' ') => {
    const date = new Date(inputTime);
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    let d = date.getDate();
    d = d < 10 ? '0' + d : d;
    let h = date.getHours();
    h = h < 10 ? '0' + h : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    if (shortDate) {
        return y + '-' + m + '-' + d;
    } else {
        return (
            y + '-' + m + '-' + d + character + h + ':' + minute + ':' + second
        );
    }
};
/**
 * md5
 * */
export const md5 = str => {
    return md5Encrypt(keys.md5 + str).substr(8, 16);
};
/**
 * 秒转换为MM：SS
 * */
export const timeFormat = second => {
    let m = second / 60, s = 0;
    if (second % 60 !== 0) {
        s = second % 60;
    }
    m = Math.floor(m);
    if (m < 10) {
        m = '0' + m;
    }
    s = Math.floor(s);
    if (s < 10) {
        s = '0' + s;
    }
    if (isNaN(m) || isNaN(s)) {
        return '00:00';
    }
    return m + ':' + s;
};

// 数字的中文显示从0开始
export const numberMapFromZero = {
    0: '一', 1: '二', 2: '三', 3: '四',
    4: '五', 5: '六', 6: '七', 7: '八',
    8: '九', 9: '十', 10: '十一', 11: '十二',
    12: '十三', 13: '十四', 14: '十五', 15: '十六',
    16: '十七', 17: '十八', 18: '十九', 19: '二十'
  };
// 时分秒转换秒
export const timeToSec = time => {
    const timeArr = time.split(':')
    const h = timeArr[0]
    const m = timeArr[1]
    const s = timeArr[2]
    return Number(h * 3600) + Number(m * 60) + Number(s)
}

// 循环获取页面元素 flag--元素类别: 'class' 'id' 'tag' el--当前元素名 way--处理样式方式 result--处理样式结果
export const handleElement = (flag, el, way, result) => {
    let $el = '';
    switch (flag) {
        case 'class':
            $el = document.getElementsByClassName(el);
            break;
        case 'id':
            $el = document.getElementById(el);
            break;
        case 'tag':
            $el = document.getElementsByTagName(el);
            break;
    }

    if ($el.length > 0) {
        for (let i = 0; i <= $el.length - 1; i++) {
            $el[i].style[way] = result;
        }
    }
}
