<template>
  <el-container>
    <PubHeader/>
    <el-main id="main-container">
      <PubMain/>
    </el-main>
    <el-footer id="footer-container">
      <PubFooter/>
    </el-footer>
  </el-container>
</template>
<script>
import PubHeader from '@/components/common/pub-header';
import PubFooter from '@/components/common/pub-footer';
import PubMain from '@/components/common/pub-main';

export default {
  name: 'Layout',
  components: { PubHeader, PubFooter, PubMain }
};
</script>