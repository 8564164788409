/**
 * @description 网管通用基类
 * @author lijing
 * @Date 2021/12/21 - 3:46 PM
 * */
import { store } from '@/core';
import keys from './keyconst';
import NodeJsency from 'nodejs-jsencrypt';
import CryptoJS from 'crypto-js';
import { comApi } from '@/api/common';

const key = CryptoJS.enc.Utf8.parse(keys.aesKey);
const iv = CryptoJS.enc.Utf8.parse(keys.ivKey);

const Encrypt = (data) => {
	let secret = CryptoJS.enc.Utf8.parse(data);
	let encrypted = CryptoJS.AES.encrypt(secret, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	return encrypted.toString();
};

const createParams = async (url, data = '') => {
	// 设置回去publicKey
	if (!store.getters.publicKey) {
		await store.dispatch('getPublicKey');
	}

	const publicKey = await store.getters.publicKey;

	// 构建网关请求中params参数
	await store.dispatch('getServerTime');
	const serverTime = await store.getters.serverTime;
	const params = {
		...data,
		time: parseInt(serverTime)
	};

	// params参数AES加密
	const paramStr = JSON.stringify(params);
	const aesParams = Encrypt(paramStr);

	// aesKey RSA 加密
	const encrypt = new NodeJsency();
	encrypt.setPublicKey(publicKey);
	const aesKey = encrypt.encrypt(keys.aesKey);
	const resourcePath = `+${url}`;

	return {
		domain: keys.domain,
		resourcePath,
		lt: +new Date(),
		publicKey,
		aesKey,
		params: aesParams,
	};
};

export const $http = async (url, data) => {
	let sendHttp = null;
	const queryParams = await createParams(url, data);
	sendHttp = await comApi.sendData(queryParams);
	return sendHttp;
};

export const $jsonp = async (options) => {
	if (!options.url || options.url === '') {
		console.error('至少需要一个url参数!');
		return;
	}
	const { url, data } = options;
	const paramArr = [];
	for (const param in data) {
		paramArr.push(encodeURIComponent(param) + '=' + encodeURIComponent(data[param]));
	}
	const paramStr = paramArr.join('&');
	return new Promise((resolve) => {
		window.success_jsonpCallback = (result) => {
			resolve(result);
		};
		let JSONP = document.createElement('script');
		JSONP.type = 'text/javascript';
		JSONP.src = `${url}?${paramStr}&jsonpCallback=success_jsonpCallback`;
		document.getElementsByTagName('head')[0].appendChild(JSONP);
		setTimeout(() => {
			document.getElementsByTagName('head')[0].removeChild(JSONP);
		}, 500);
	});
};

