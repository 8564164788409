/**
 * @description 核心基类入口
 * @author lijing
 * @Date 2021/12/1 - 4:50 PM
 * */
import { useStore, store } from '@Core/middleware/useStore';
import { router, useRouter } from '@Core/middleware/useRouter';

const bootstrap = (app) => {
    useStore();
    useRouter(app);
};

export { bootstrap, store, router };