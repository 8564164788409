/**
 * @description 题库
 * @author lihui
 * @Date 2021/12/28 - 11:28 AM
 * */
import Layout from '@Core/layout/aside';

const questionBankRoute = [
    {
        path: '/questionBank',
        name: 'QuestionBank',
        component: Layout,
        redirect: '/questionBank/index',
        children: [
            {
                path: 'index',
                name: 'QuestionBank-index',
                component: () => import('@/views/QuestionBank/index'),
                meta: { title: '题库练习' }
            },
            {
                path: 'allAnalysis',
                name: 'allAnalysis',
                component: () => import('@/views/QuestionBank/allAnalysis'),
                meta: { title: '全部解析' }
            },
            {
                path: 'doExam',
                name: 'doExam',
                component: () => import('@/views/QuestionBank/doExam'),
                meta: { title: '题库系统' }
            }
        ]
    }
];

export default questionBankRoute;
