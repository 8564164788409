/**
 * @description 个人中心
 * @author lijing
 * @Date 2021/12/27 - 10:33 AM
 * */
import Layout from '@Core/layout';

const examRoute = [
    {
        path: '/exam',
        name: 'Exam',
        component: Layout,
        redirect: '/exam/list',
        children: [
            {
                path: 'list',
                name: 'ExamList',
                component: () => import('@/views/ExamView'),
                meta: { title: '考试列表' }
            },
            {
                path: 'rankList',
                name: 'RankList',
                component: () => import('@/views/ExamView/rankList'),
                meta: { title: '排行榜' }
            },
            {
                path: 'personScore',
                name: 'PersonScore',
                component: () => import('@/views/ExamView/personScore'),
                meta: { title: '个人成绩' }
            },
            {
                path: 'examError',
                name: 'ExamError',
                component: () => import('@/views/ExamView/examError'),
                meta: { title: '错误页面' }
            }
        ]
    }
];

export default examRoute;
