import Layout from '@Core/layout/videoPlay';

const helpRoute = [
    {
        path: '/help',
        name: 'help',
        component: Layout,
        children: [
            {
                path: 'index',
                name: 'Help',
                component: () => import('@/views/CourseView/help'),
                meta: { title: '看课帮助' }
            }
        ]
    }
];

export default helpRoute;
