/**
 * @description 自动注入状态管理
 * @author lijing
 * @Date 2021/12/2 - 11:59 AM
 * */
import Vue from 'vue';
import Vuex from 'vuex';
import { isEmpty } from '@Core/utils';

Vue.use(Vuex);

const storeFiles = require.context('@/store', false, /\.js$/);

const store = new Vuex.Store({
    strict: true
});


const useStore = () => {
    storeFiles.keys().map((path) => {
        const value = storeFiles(path).default;
        const moduleName = path.replace(/^\.\/(.*)\.\w+$/, '$1');
        if (!isEmpty(value)) {
            store.registerModule(moduleName, value);
        }
    });
};

export { store, useStore };