/**
 * @description 课程播放
 * @author lijing
 * @Date 2022/1/7 - 4:28 PM
 * */

import { courseApi } from '@/api/course';
import { isEmpty } from '@Core/utils';

const videoStore = {
    state: {
        // 是否展开收起右侧功能区
        showCourse: true,
        // 当前课程下所有视频列表
        allVideoList: [],
        // 课程相关参数
        courseData: {
            courseID: '',
            cwareID: '',
            courseName: '',
            appID: '',
            userID: ''
        },
        // 视频列表
        coursewareList: [],
        // 所有讲义
        allLectureList: [],
        // 笔记列表
        noteList: [],
        // 答疑信息
        answerInfo: null,
        currentLectureInfo: null
    },
    getters: {
        showCourse: state => state.showCourse,
        allVideoList: state => state.allVideoList,
        courseData: state => state.courseData,
        coursewareList: state => state.coursewareList,
        allLectureList: state => state.allLectureList,
        currentLectureInfo: state => state.currentLectureInfo,
        noteList: state => state.noteList,
        answerInfo: state => state.answerInfo
    },
    mutations: {
        SET_SHOW_COURSE(state, data) {
            state.showCourse = data;
        },
        SET_ALL_VIDEO_LIST(state, list) {
            state.allVideoList = list;
        },
        SET_COURSE_DATA(state, data) {
            state.courseData = data;
        },
        SET_COURSEWARE_LIST(state, list) {
            state.coursewareList = list;
        },
        MODIFY_COURSEWARE_LIST(state, { index, key, value }) {
            state.coursewareList[index][key] = value;
        },
        SET_CURRENT_LECTURE(state, data) {
            state.currentLectureInfo = data;
        },
        SET_ALL_LECTURE_LIST(state, data) {
            state.allLectureList = data;
        },
        SET_ANSWER_INFO(state, data) {
            state.answerInfo = data;
        },
        SET_NOTE_LIST(state, data) {
            state.noteList = data;
        }
    },
    actions: {
        setShowCourse({ commit }, data) {
            commit('SET_SHOW_COURSE', data);
        },
        setCourseData({ commit }, data) {
            commit('SET_COURSE_DATA', data);
        },
        setCoursewareList({ commit }, { videoID, list }) {
            let treeView = [];
            list.forEach(item => {
                let root = {
                    title: item.smallListName,
                    id: item.smallListID,
                    isOpen: false
                };

                if (item.videoList && item.videoList.length > 0) {
                    let children = [];
                    item.videoList.forEach(n => {

                        if (parseInt(videoID) === n.videoID) {
                            root.isOpen = true;
                        }
                        children.push({
                            label: n.videoName,
                            videoID: n.videoID,
                            percent: n.percent,
                            videoLen: n.videoLen,
                            lastLearnPoint: n.lastLearnPoint
                        });
                    });
                    root.children = children;
                }
                treeView.push(root);
            });

            commit('SET_COURSEWARE_LIST', treeView);
            return treeView;
        },
        getAnswerList({ commit }, data) {
            commit('SET_ANSWER_INFO', data);
        },
        getNoteList({ commit }, param) {
            const iconurl = param.iconurl;
            delete param.iconurl;
            const params = { ...param };
            courseApi.getUserNoteContentApi(params).then(data => {
                const { success, result } = data;
                if (success && !isEmpty(result)) {
                    result.forEach(item => {
                        item.src = iconurl;
                    });
                    commit('SET_NOTE_LIST', result);
                }
            });
        }
    }
};

export default videoStore;
