/**
 * @description svg图标解析
 * @author lijing
 * @Date 2021/12/1 - 4:25 PM
 * */
import Vue from 'vue';
import SvgIcon from '@Core/base/components/SvgIcon';

Vue.component('icons', SvgIcon);

const req = require.context('@/assets/icons', false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);

requireAll(req);