/**
 * @description 自动集成路由管理，路由拦截器
 * @author lijing
 * @Date 2021/12/2 - 12:23 PM
 * */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@Core/layout/aside';
import NProgress from 'nprogress';
import $Cookie from '@Core/utils/cookie';
import { Base64 } from 'js-base64';
import { isEmpty, isJsonString } from '@Core/utils';
import settings from '../../../setting';

Vue.use(VueRouter);
const routerFiles = require.context('@/router', false, /\.js$/);
const whiteList = [
    '/login',
    '/forgot/confirm',
    '/forgot/certification',
    '/forgot/reset',
    '/forgot'
]; // no redirect whitelist
const forgetList = [
    '/forgot/confirm',
    '/forgot/certification',
    '/forgot/reset',
    '/forgot'
];
const projectTitle = settings.title || '税务学习云平台';
const getPageTitle = (title = '') => {
    if (title) {
        return `${ title } - ${ projectTitle }`;
    }
    return `${ title }`;
};

const constantRoutes = [
    {
        path: '/',
        name: 'HomeIndex',
        redirect: '/',
        component: Layout,
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('@/views/Home'),
                meta: { title: '个人主页' }
            },
            {
                path: 'infoDetail/:id/:flag/:name',
                name: 'InfoDetail',
                component: () => import('@/views/Home/detail'),
                meta: { title: '资讯详情' }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login'),
        meta: { title: '登录' }
    },
    {
        path: '/study/:id',
        name: 'Logins',
        component: () => import('@/views/Login'),
        meta: { title: '登录' }
    },
    {
        path: '/404',
        component: () => import('@Core/layout/error-page')
    }
];

const createRouter = () =>
    new VueRouter({
        base: process.env.VUE_APP_SCHOOL_DOMAIN,
        mode: 'history',
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes
    });

const router = createRouter();

// 处理重复点击导航路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const useRouter = (app) => {
    const asyncRoutes = routerFiles.keys().reduce((module, path) => {
        const value = routerFiles(path);
        value.default.forEach((item) => {
            module.push(item);
        });
        return module;
    }, []);

    asyncRoutes.map((routes) => {
        app.$router.addRoute(routes);
    });

    app.$router.addRoute({
        path: '/:catchAll(.*)',
        name: '404',
        redirect: '/404'
    });
};
NProgress.configure({ showSpinner: false });
router.beforeEach((to, from, next) => {
    NProgress.start();
document.title = getPageTitle(to.meta.title);

let userInfo = $Cookie.get('userInfo');
if (userInfo) {
	userInfo = Base64.decode(userInfo);
	if (userInfo && isJsonString(userInfo)) {
		userInfo = JSON.parse(userInfo);
	}
}
if (!userInfo || !userInfo.sid) {
	if (whiteList.indexOf(to.path) !== -1 || to.path.indexOf('study') !== -1) {
		next();
	} else {
		next({
			path: '/login',
			query: {
				url: to.path,
				qs: !isEmpty(to.query)
					? encodeURIComponent(JSON.stringify(to.query) || '')
					: ''
			}
		});
		NProgress.done();
	}
} else {
	if (to.path === '/login' || to.path.indexOf('study') !== -1) {
		next({ path: '/' });
	} else {
		next();
	}
}

// 忘记密码以外的页面删除忘记密码所构建的cookie数据
if (forgetList.indexOf(to.path) === -1) {
	$Cookie.remove('forget');
}
});

router.afterEach(() => {
    NProgress.done();
});

export { router, useRouter };
