<template>
  <el-container>
    <PubHeader :isShowHelpBtn="true"/>
    <el-main id="main-container">
      <router-view/>
    </el-main>
    <el-footer id="footer-container">
      <PubFooter/>
    </el-footer>
  </el-container>
</template>
<script>
import PubHeader from '@/components/common/pub-header';
import PubFooter from '@/components/common/pub-footer';

export default {
  name: 'AsideLayout',
  components: { PubHeader, PubFooter }
};
</script>
<style lang="scss" scoped>
#main-container {
  padding: 0;
}
</style>
