/**
 * @description 项目配置
 * @author lijing
 * @Date 2021/12/1 - 9:12 AM
 * */

module.exports = {
    // 项目名称
    title: '正保云平台',
    domain: '.chinaacc.com',
    schoolDomain: 'beijing',
    bizID: '1',
    avatar: 'http://www.chinaacc.com/upload/resources/image/2022/02/17/1669401.png'
};
