/**
 * @description 用户认证接口
 * @author lijing
 * @Date 2021/12/22 - 9:11 AM
 * */
import { $http, $jsonp } from '@Core/plugins/service';

class AuthApi {
	// 获取图片验证码
	getRandCodeApi() {
		return $http('/userLogin/randcode');
	}
	// 获取登录路由
	getLoginList() {
		return $http('/userLogin/getDomains');
	}
	// 账号密码登录
	accountLoginApi(params) {
		return $http('/userLogin/login', params);
	}

	// 获取短信验证码
	getSmsCodeApi(params) {
		return $http('/userLogin/getPhoneVerifyCode', params);
	}

	// 短信验证码登录
	mobileLoginApi(params) {
		return $http('/userLogin/thirdPartyLogin', params);
	}

	// 退出登录
	logoutApi(sid) {
		return $http('/userLogin/logout', { sid: sid });
	}

	// 忘记密码-校验账号和手机号
	checkUserAccountApi(params) {
		return $http('/forgetPwd/checkUserName', params);
	}

	// 忘记密码-校验手机+发送验证码
	checkMobileSmsApi(params) {
		return $http('/forgetPwd/checkUserAndPhone', params);
	}

	// 忘记密码-校验用户+短信验证码
	checkUserSmsCodeApi(params) {
		return $http('/forgetPwd/checkVerifyCode', params);
	}

	// 忘记密码-重置密码
	resetPwdApi(params) {
		return $http('/forgetPwd/resetPwd', params);
	}

	//测试 $jsonp
	testJsonpApi(params) {
		return $jsonp({
			url: '//cmsapi.cdeledu.com/api/precision/predict/courseEduPredictNew',
			data: params
		})
	}
}

const authApi = new AuthApi();
export { authApi };
