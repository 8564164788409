/**
 * @description 课程播放页
 * @author lijing
 * @Date 2022/1/6 - 6:09 PM
 * */

import Layout from '@Core/layout/videoPlay';

const courseViewRoute = [
    {
        path: '/courseView',
        name: 'CourseView',
        component: Layout,
        children: [
            {
                path: ':cwareId',
                name: 'CourseView-video',
                component: () => import('@/views/CourseView'),
                meta: { title: '课程学习' }
            }
        ]
    },
    {
        path: '/videoList',
        name: 'VideoList',
        component: Layout,
        children: [
            {
                path: ':cwareId',
                name: 'VideoList-video',
                component: () => import('@/views/VideoList'),
                meta: { title: '直播回看' }
            }
        ]
    }
];

export default courseViewRoute;
