/**
 * @description
 * @author lijing
 * @Date 2021/12/3 - 6:09 PM
 * */
import $Cookie from '@Core/utils/cookie';
import { comApi } from '@/api/common';
import { Base64 } from 'js-base64';
import { isEmpty, isJsonString } from '@Core/utils';
import { router } from '@/core';
import { authApi } from '@/api/auth';
import { Message } from 'element-ui';

const authStore = {
    state: {
        userInfo: '',
        publicKey: $Cookie.get('publicKey') || null,
        serverTime: null
    },
    getters: {
        userInfo: state => state.userInfo,
        publicKey: state => state.publicKey,
        serverTime: state => state.serverTime
    },
    mutations: {
        SET_PUBLIC_KEY(state, key) {
            state.publicKey = key;
            $Cookie.set('publicKey', key);
        },
        SET_SERVER_TIME(state, time) {
            state.serverTime = time;
        },
        SET_USER_INFO(state, info) {
            info = info || {};
            state.userInfo = info;
            let vInfo = JSON.stringify(info);
            $Cookie.set('sid', info.sid);
            $Cookie.set('cdeluid', info.uid);
            $Cookie.set('userInfo', Base64.encode(vInfo));
        }
    },
    actions: {
        // 获取publicKey
        async getPublicKey({ commit }) {
            await comApi.getPublicKeyApi().then(data => {
                const { success, result } = data;
                if (success && (result && result.length)) {
                    commit('SET_PUBLIC_KEY', result);
                }
            });
        },
        // 获取服务器时间
        async getServerTime({ commit }) {
            await comApi.getServerTime().then(data => {
                const { success, result } = data;
                if (success && result) {
                    commit('SET_SERVER_TIME', result);
                }
            });
        },
        // 设置用户信息
        setUserInfo({ commit, dispatch }, data) {
            const { result, needRemember = false } = data;
            if (needRemember) {
                $Cookie.set('remember', Base64.encode(result.username), 7);
            } else {
                const remember = $Cookie.get('remember');
                if (remember) {
                    $Cookie.remove('remember', 7);
                }
            }

            commit('SET_USER_INFO', result);
        },
        // 获取用户信息
        getUserInfo({ getters }) {
            let userInfo = getters.userInfo;
            if (userInfo && isJsonString(userInfo)) {
                return JSON.parse(Base64.decode(userInfo));
            } else {
                let cUser = $Cookie.get('userInfo') || null;
                if (cUser) {
                    let bUser = Base64.decode(cUser);
                    if (isJsonString(bUser)) {
                        return JSON.parse(bUser);
                    } else {
                        return undefined;
                    }
                } else {
                    return undefined;
                }
            }
        },
        // 退出登录
        async logout({ dispatch }) {
            const userInfo = await dispatch('getUserInfo');
            if (isEmpty(userInfo)) {
                dispatch('removeAuth');
            } else {
                await authApi.logoutApi(userInfo.sid).then(data => {
                    const { success, result } = data;
                    if (success && result) {
                        Message({
                            type: 'success',
                            message: '退出登录',
                            duration: 1500,
                            onClose() {
                                dispatch('removeAuth');
                            }
                        });
                    }
                });
            }
        },
        // 清除所有保存cookie
        removeAuth() {
            $Cookie.remove('userInfo');
            router.push({ path: '/login' });
        },
        resetUserInfo({ commit }, data) {
            return new Promise(resolve => {
                if (!isEmpty(data)) {
                    let vInfo = JSON.stringify(data);
                    $Cookie.set('userInfo', Base64.encode(vInfo));
                    resolve();
                }
            });
        }
    }
};
export default authStore;