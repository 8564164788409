<template>
  <div class="app-main " :style="'min-height:' + contentHeight">
      <router-view/>
  </div>
</template>
<script>
export default {
  name: 'PubMain',
  data() {
    return {
      contentHeight: ''
    }
  },
  created() {
    this.contentHeight = document.body.offsetHeight - 205 + 'px'
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixin";

.app-main {
  //min-height: 450px;
  //height:calc(100% - 165px);
  position: relative;
  overflow: hidden;
}
</style>
