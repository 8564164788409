/**
 * @description 忘记密码
 * @author lijing
 * @Date 2021/12/28 - 2:51 PM
 * */
import Layout from '@Core/layout';

const forgotRoute = [
    {
        path: '/forgot',
        name: 'Forgot',
        component: Layout,
        redirect: '/forgot/confirm',
        children: [
            {
                path: 'confirm',
                name: 'Forgot-confirm',
                component: () => import('@/views/Forgot/confirm'),
                meta: { title: '忘记密码-确认账号' }
            },
            {
                path: 'certification',
                name: 'Forgot-certification',
                component: () => import('@/views/Forgot/certification'),
                meta: { title: '忘记密码-安全认证' }
            },
            {
                path: 'reset',
                name: 'Forgot-reset',
                component: () => import('@/views/Forgot/reset'),
                meta: { title: '忘记密码-重置密码' }
            }
        ]
    }
];

export default forgotRoute;