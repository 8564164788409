/**
 * @description 个人中心
 * @author lijing
 * @Date 2021/12/27 - 10:33 AM
 * */
import Layout from '@Core/layout';

const personalRoute = [
    {
        path: '/personal',
        name: 'Personal',
        component: Layout,
        children: [
            {
                path: 'index',
                name: 'PersonCenter',
                component: () => import('@/views/Personal'),
                meta: { title: '个人中心' }
            }
        ]
    }
];

export default personalRoute;