/**
 * @description 接口配置
 * @author lijing
 * @Date 2021/12/22 - 10:19 AM
 * */

export default {
    // aes密钥
    aesKey: '823s4125660ijf;*',
    // iv密钥
    ivKey: 'qyu148#4(1p_1^4;',
    // domain
    domain: 'taxapi',
    // md5签名
    md5: '1813ACCOUNT'
};
