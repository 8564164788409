import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import '@Core/plugins/icons';
import { bootstrap, store, router } from '@/core';
import Element from 'element-ui';
import 'nprogress/nprogress.css';
import '@/assets/styles/main.scss';
import settings from '../setting';
import $Cookie from '@Core/utils/cookie';
import axios from 'axios';

Vue.use(Element);

Vue.prototype.$settings = settings;
Vue.prototype.$Cookie = $Cookie;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

// 计算器
window.math.config({ number: 'BigNumber' });
Vue.prototype.$math = window.math;
window['_calc_element'] = 'test';

bootstrap(new Vue({ el: '#app', router, store, render: h => h(App) }));

Vue.prototype.formatHMS = function(value){
    let len = value.split(":");
            if(len.length == 3){
                let hour = len[0];
                let min = len[1];
                let sec = len[2];
                return Number(hour*3600) + Number(min*60)+Number(sec);
            }else if(len.length == 2){
                let min = len[0];
                let sec = len[1];
                return Number(min*60)+Number(sec);
            }else if(len.length == 1){
                let sec = len[0];
                return Number(sec);
            }else{
                return 0;
            }
}

Vue.prototype.transformData = function(cwareVideoList){
    let cwareList = [];
    cwareVideoList.forEach((v,i)=>{
        let cware = {};
        cware.label = v.cwareName;
        cware.level = 1;
        cware.percentage = v.cwareStudyProgress;
        cware.studyTime = v.cwareStudyLen;
        cware.totalTime = v.forecastTimeLen;
        cware.cwareID = v.cwareID;
        cware.cwID = v.cwID;
        let chapterList = [];
        v.chapterList.forEach((c,j)=>{
            let chapter = {};
            chapter.label = c.smallListName;
            chapter.level = 2;
            chapter.percentage = c.smallListProgress;
            chapter.studyTime = c.smallListStudyLen;
            chapter.totalTime = c.smallListTime;
            chapter.cwareID = c.cwareID;
            chapterList.push(chapter);
            let listVideo = [];
            c.listVideo.forEach((l,m)=>{
                let video = {};
                video.label = l.videoName;
                video.level = 3;
                video.percentage = l.videoProgress;
                video.studyTime = l.videoStudyLen;
                video.totalTime = l.videoLen;
                video.cwareID = c.cwareID;
                video.videoID = l.videoID;
                video.backType = l.backType;
                video.mobileBackPath = l.mobileBackPath;
                video.backPath = l.backPath;
                listVideo.push(video);
            });
            chapter.children = listVideo;
        });
        cware.children = chapterList;
        cwareList.push(cware);
    });
    return cwareList;
}


